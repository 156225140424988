<template>
    <b-card title="Merge">
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>
                    <b-col md="6">
                        <b-form-group label="Original Customer">
                            <validation-provider #default="{ errors }" name="Original Customer" rules="required">
                                <b-form-input v-model="originalCustomer" :state="errors.length > 0 ? false : null"
                                    placeholder="Please Enter Original Customer" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Duplicate Customer">
                            <validation-provider #default="{ errors }" name="Duplicate Customer" rules="required">
                                <b-form-input v-model="duplicateCustomer" :state="errors.length > 0 ? false : null" placeholder="Please Enter Duplicate Customer" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="12" class="text-center">
                        <b-button variant="primary" type="submit" @click.prevent="validationForm">Merge Customer
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard, BCol, BRow, BButton, BForm, BFormGroup, BFormInput
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
    components: {
        ValidationProvider, ValidationObserver, BCard, BCol, BRow, BButton, BForm, BFormGroup, BFormInput
    },
    data() {
        return {
            required,
            originalCustomer: '',
            duplicateCustomer: '',

        }
    },
    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    this.$swal({
                        title: '',
                        text: 'Customer Merged Successfully!',
                        icon: 'success',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                } else {
                    this.$swal({
                        title: '',
                        text: 'Please Fill Mandatory Fields!',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-secondary',
                        },
                        buttonsStyling: false,
                    })
                }
            })
        },
    },
}
</script>